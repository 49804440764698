// JavaScript Document
;(function ($) {


	/*
	 *
	 * Application principale
	 *
	 */
	var appChild = {

			init : function() {
				this.eventListeners();
				this.sliders();
				this.slidersTab();
			},

			eventListeners : function() {

				// EventListeners

				/* ----------------------------------------------------- */
				/* BACK TO TOP */
				/* ----------------------------------------------------- */

				var btn = $('#buttonTop');

				$(window).scroll(function() {
					if ($(window).scrollTop() > 300) {
						btn.addClass('show');
					} else {
						btn.removeClass('show');
					}
				});

				btn.on('click', function(e) {
					e.preventDefault();
					$('html, body').animate({scrollTop:0}, '300');
				});

				$(document)

					/* ----------------------------------------------------- */
					/* SELECTS de region et thematics */
					/* ----------------------------------------------------- */
					.on('click', 'select[name="choose_region"]', function(event) {
						event.preventDefault();
						/* Act on the event */
						// var id =
						// console.log(event);
					})

					/* ----------------------------------------------------- */
					/* EVENEMENTS GENERIQUES */
					/* ----------------------------------------------------- */

					.on('click', '.prevent-default', function(e) {
						e.preventDefault();
					})

					/* ----------------------------------------------------- */
					/* COOKIES */
					/* ----------------------------------------------------- */
					.on('click', '.open-cookies', function(event) {
						event.preventDefault();

						tarteaucitron.userInterface.openPanel();
						return false;
					})

					/* ----------------------------------------------------- */
					/* Toogle search on map */
					/* ----------------------------------------------------- */
					.on('click', '.btn-toggle-map-search', function(event) {
						event.preventDefault();
						$('body').toggleClass('map-search-open');
					})
					.on('click', '.filters_container__close', function(event) {
						event.preventDefault();
						$('body').removeClass('map-search-open');
					})

					/* ----------------------------------------------------- */
					/* TOOGLE MAP VIEW */
					/* ----------------------------------------------------- */
					.on('click', '.toggle-map', function(e) {

						e.preventDefault();

						$('body').toggleClass('map-toggled');

						map.reinit();

					})



					/* ----------------------------------------------------- */
					/* TABS DE CONTENU */
					/* ----------------------------------------------------- */
					.on('click', '.single__tabs .tab-button', function(event) {
						event.preventDefault();
						/* Act on the event */
						var content = $(this).attr('href');

						$('.single__tabs .tabs').find('.tab').removeClass('tab-active');
						$(this).parent().addClass('tab-active');

						$(this).parents('.tabs').find('.tab-content').removeClass('tab-content-active');
						$(this).parents('.tabs').find('.tab-content-mobile').removeClass('tab-content-active');
						$(this).parents('.tabs').find(content).addClass('tab-content-active');
						$(this).parents('.tabs').find(content+'-mobile').addClass('tab-content-active');

					})

					/* ----------------------------------------------------- */
					/* TABS DE CONTENU */
					/* ----------------------------------------------------- */
					.on('click', '.tab-buttons .icon', function(event) {
						event.preventDefault();
						/* Act on the event */
						var content = $(this).data('href');

						$('.tab-buttons .icon').removeClass('tab-active');
						$(this).addClass('tab-active');

						$('.tab-content').removeClass('tab-content-active');
						// $('.tab-content-mobile').removeClass('tab-content-active');
						$(content).addClass('tab-content-active');

						if ( $('.column-images-slider.slick-initialized').length ) {
							$('.column-images-slider.slick-initialized').slick('unslick');
							$('.column-images-mini-slider.slick-initialized').slick('unslick');
						}

						appChild.slidersTab();

						$('html, body').animate({scrollTop: $(content).offset().top }, 500);

					})

					/* ----------------------------------------------------- */
					/* APPEL D'UN ITEM EN AJAX */
					/* ----------------------------------------------------- */
					.on('click', '.btn-single-ajax', function(e) {
						e.preventDefault();
						ajax.singleAjax($(this));
					})

					/* ----------------------------------------------------- */
					/* TOGGLE LANGUAGE */
					/* ----------------------------------------------------- */
					.on('click', '.toggle-lang', function(event) {
						event.preventDefault();

						$('body').toggleClass('lang-open');
					})

					/* ----------------------------------------------------- */
					/* OUVERTURE DES SOUS MENUS */
					/* ----------------------------------------------------- */
					.on('click', '.menu-item-has-children >a', function(event) {
						if ( $(window).innerWidth() < 780 ) {
							event.preventDefault();
							/* Act on the event */
							var id = $(this).parent().data('id');

							if ( id && id != 0 ) {
								$(this).parent().find('.sub-menu-item-' + id).toggleClass('visible');
							}
						}
					})

					/* ----------------------------------------------------- */
					/* OUVERTURE FORUM FORM ACCORDION ANSWER */
					/* ----------------------------------------------------- */
					.on('click', '.forum__results_accordion_item_body_answer_btn .btn', function(e) {
						e.preventDefault();

						$(this).parents('.forum__results_accordion_item').find('.forum__results_accordion_item_footer').toggleClass('visible');
					})

					.on('click', '.forum__results_accordion_item_header', function(e) {

						$(this).parents('.forum__results_accordion_item').find('.forum__results_accordion_item_body').toggleClass('visible');
					})
				;

			},

			sliders : function() {
				/* Slider home  */
				$('.site-home-slider_slider.slider').slick({
					infinite: true,
					speed: 400,
					cssEase: 'ease-out',
					mobileFirst: true,
					slidesToShow: 1,
					lazyLoad: 'progressive',
					slidesToScroll: 1,
					dots: true,
					arrows: false,
					autoplay: true,
					autoplaySpeed: 3000,
				})
					,
					/* Slider home bas */
					$('.site-home-contenu_slider.slider').slick({
						infinite: true,
						speed: 400,
						cssEase: 'ease-out',
						mobileFirst: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						lazyLoad: 'progressive',
						prevArrow: '<a class="slick-arrow slick-prev"><i class="fas fa-chevron-left"></i></a>',
						nextArrow: '<a class="slick-arrow slick-next"><i class="fas fa-chevron-right"></i></a>',
						dots: false,
						arrows: true,
						autoplay: true,
						autoplaySpeed: 3000,
					})
					,
					$('.site-page-contenu_slider.slider').slick({
						infinite: true,
						speed: 400,
						cssEase: 'ease-out',
						mobileFirst: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						lazyLoad: 'progressive',
						dots: true,
						arrows: false,
						autoplay: true,
						autoplaySpeed: 3000,
					});
			}
			,

			slidersTab : function() {
				if ( $('.column-images-mini-slider').length ) {
					var asNavFor = $('.tab-content-active').find('.column-images-mini-slider');
				} else {
					var asNavFor = null;
				}

				/* Slider dans une colonne */
				/* ----------------------------------------------------- */
				$('.column-images-slider').slick({
					infinite: true,
					speed: 400,
					cssEase: 'ease-out',
					mobileFirst: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					// appendDots: $('.slider-dots'),
					// appendArrows: $('.slider-arrows'),
					// dots: true,
					arrows: true,
					autoplay: true,
					autoplaySpeed: 3000,
					responsive: [
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								// dots: true,
								arrows: true,
								// appendDots: $('.slider-dots'),
								// appendArrows: $('.slider-arrows'),
								prevArrow: '<a class="slick-arrow slick-prev"></a>',
								nextArrow: '<a class="slick-arrow slick-next"></a>'
							}
						},
						{
							breakpoint: 1200,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								// dots: true,
								arrows: true,
								// appendDots: $('.slider-dots'),
								// appendArrows: $('.slider-arrows'),
								prevArrow: '<a class="slick-arrow slick-prev"></a>',
								nextArrow: '<a class="slick-arrow slick-next"></a>'
							}
						},

					],
					asNavFor : asNavFor
				});

				/* Mini slider si jamais il existe */
				/* ----------------------------------------------------- */
				if ( $('.column-images-mini-slider').length ) {
					var asNavFor2 = $('.tab-content-active').find('.column-images-slider');
				} else {
					var asNavFor2 = null;
				}
				$('.column-images-mini-slider').slick({
					infinite: true,
					speed: 400,
					cssEase: 'ease-out',
					mobileFirst: true,
					slidesToShow: 2,
					slidesToScroll: 1,
					// appendDots: $('.slider-dots'),
					// appendArrows: $('.slider-arrows'),
					// dots: true,
					arrows: false,
					autoplay: true,
					autoplaySpeed: 3000,
					focusOnSelect: true,
					responsive: [
						{
							breakpoint: 639,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 1,
								// dots: true,
								// arrows: true,
								// appendDots: $('.slider-dots'),
								// appendArrows: $('.slider-arrows'),
								// prevArrow: '<a class="slick-arrow slick-prev"></a>',
								// nextArrow: '<a class="slick-arrow slick-next"></a>'
							}
						},
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 4,
								slidesToScroll: 1,
								// dots: true,
								// arrows: true,
								// appendDots: $('.slider-dots'),
								// appendArrows: $('.slider-arrows'),
								// prevArrow: '<a class="slick-arrow slick-prev"></a>',
								// nextArrow: '<a class="slick-arrow slick-next"></a>'
							}
						},
					],
					asNavFor : asNavFor2
				});

			},

			intro : function() {

				$('.intro').addClass('animate');
				setTimeout(function() {
					$('.intro').addClass('animated');
				}, 4200);

			},

			update : function() {

			},

			parallax : function() {

				// init controller
				var controller = new ScrollMagic.Controller();

				/* Home section */
				/* ----------------------------------------------------- */
				if ( $('section.home_section').length ) {
					$('section.home_section').each(function() {
						var tween = new TimelineMax().add([
							TweenMax.to($(this).find('#groupe1'), 1, {y: '-20%', rotation: 15, transformOrigin: "center", autoAlpha: 0}),
							TweenMax.to($(this).find('#groupe2'), 1, {y: '-10%', rotation: 15, transformOrigin: "center", autoAlpha: 0})
							// TweenMax.from($(this).find('.txt'), 1, {x: -75}),
							// TweenMax.from($(this).find('.txt .btn'), 1, {y: 40}),
							// TweenMax.from($(this).find('.txt .text'), 1, {x: -50, delay: 0.25}),
						]);

						var Sc = new ScrollMagic.Scene({
								triggerElement: this,
								triggerHook: "onLeave",
								duration: '100%',
							})
								.setTween(tween)
								.addTo(controller)
						;
					});
				}

				/* Bandeau */
				/* ----------------------------------------------------- */
				if ( $('section.bandeau').length ) {
					$('section.bandeau').each(function() {
						var tween = new TimelineMax().add([
							TweenMax.to($(this).find('.bottom-left #groupe1, .bottom-right #groupe1, .top-right #groupe1'), 1, {y: '-20%', rotation: 15, transformOrigin: "center", autoAlpha: 0}),
							TweenMax.to($(this).find('.bottom-left #groupe2, .bottom-right #groupe2, .top-right #groupe2'), 1, {y: '-10%', rotation: 15, transformOrigin: "center", autoAlpha: 0})
							// TweenMax.from($(this).find('.txt'), 1, {x: -75}),
							// TweenMax.from($(this).find('.txt .btn'), 1, {y: 40}),
							// TweenMax.from($(this).find('.txt .text'), 1, {x: -50, delay: 0.25}),
						]);

						var Sc = new ScrollMagic.Scene({
								triggerElement: this,
								triggerHook: "onLeave",
								duration: '100%',
							})
								.setTween(tween)
								.addTo(controller)
						;
					});
				}

				/* left_text_right_dish  */
				/* ----------------------------------------------------- */
				if ( $('section.left_text_right_dish').length ) {
					$('section.left_text_right_dish').each(function() {

						if ( $(this).hasClass('inverse') ) {
							var tween = new TimelineMax().add([
								TweenMax.from($(this).find('.dish'), 1, {y: '-20%', rotation: 15, transformOrigin: "center"}),

								TweenMax.from($(this).find('#groupe1'), 1, {y: '-15%', rotation: 15, transformOrigin: "center", autoAlpha: 0}),
								TweenMax.from($(this).find('#groupe2'), 1, {y: '15%', rotation: 15, transformOrigin: "center", autoAlpha: 0}),
								TweenMax.from($(this).find('#taches'), 1, {y: '-15%', rotation: 15, transformOrigin: "center", autoAlpha: 0}),

							]);
						}
						else {
							var tween = new TimelineMax().add([
								TweenMax.to($(this).find('.dish'), 1, {y: '20%', rotation: 15, transformOrigin: "center"}),

								TweenMax.from($(this).find('#groupe1'), 1, {y: '-20%', rotation: 15, transformOrigin: "center", autoAlpha: 0}),
								TweenMax.from($(this).find('#groupe2'), 1, {y: '-40%', rotation: 15, transformOrigin: "center", autoAlpha: 0}),
								TweenMax.from($(this).find('#taches'), 1, {y: '-20%', rotation: 15, transformOrigin: "center", autoAlpha: 0}),

							]);
						}

						var Sc = new ScrollMagic.Scene({
								triggerElement: this,
								triggerHook: 1,
								duration: '200%',
							})
								.setTween(tween)
								.addTo(controller)
						;
					});
				}

				/* News  */
				/* ----------------------------------------------------- */
				if ( $('section.news').length ) {
					$('section.news').each(function() {
						var tween = new TimelineMax().add([
							TweenMax.from($(this).find('.pattern.top-left'), 1, {y: '-20%'}),
							TweenMax.from($(this).find('.pattern.center-right'), 1, {y: '20%'}),

						]);

						var Sc = new ScrollMagic.Scene({
								triggerElement: this,
								triggerHook: 1,
								duration: '150%',
							})
								.setTween(tween)
								.addTo(controller)
						;
					});
				}

				/* Socialwall  */
				/* ----------------------------------------------------- */
				if ( $('section.social_wall').length ) {
					$('section.social_wall').each(function() {
						var tween = new TimelineMax().add([
							// TweenMax.from($(this).find('.pattern.top-center svg'), 1, {y: '0%'}),
							TweenMax.from($(this).find('.pattern.top-center svg #groupe1'), 1, {y: '20%', rotation: 15, transformOrigin: 'center'}),
							TweenMax.from($(this).find('.pattern.top-center svg #groupe2'), 1, {y: '40%', rotation: -15, transformOrigin: 'center'}),

							TweenMax.from($(this).find('.pattern.bottom-left'), 1, {y: '-75%'}),
							TweenMax.from($(this).find('.pattern.bottom-left #feuille'), 1, {y: '50%', rotation: 25, transformOrigin: 'center'}),
							TweenMax.from($(this).find('.pattern.bottom-left #poivron'), 1, {y: '20%', rotation: -25, transformOrigin: 'center'}),
							TweenMax.from($(this).find('.pattern.bottom-left #oignon'), 1, {y: '30%', rotation: 25, transformOrigin: 'center'}),
							TweenMax.from($(this).find('.pattern.bottom-left #baies'), 1, {y: '50%', rotation: -25, transformOrigin: 'center'}),

						]);

						var Sc = new ScrollMagic.Scene({
								triggerElement: this,
								triggerHook: 1,
								duration: '100%',
							})
								.setTween(tween)
								.addTo(controller)
						;
					});
				}

				/* Texte Image */
				/* ----------------------------------------------------- */
				if ($('.txt-img, .img-txt').length) {
					$('.txt-img, .img-txt').each(function() {
						//console.log($(window).innerHeight() / -20);
						var tween2 = new TimelineMax().add([
							TweenMax.to($(this).find('.img img'), 1, {y: $(window).innerHeight() / -5}),
							// TweenMax.from($(this).find('.txt'), 1, {y: '-60%'}),
							TweenMax.to($(this).find('#groupe1'), 1, {y: '50%'}),
							TweenMax.to($(this).find('#groupe2'), 1, {y: '90%'}),
						]);

						var Sc = new ScrollMagic.Scene({
								triggerElement: this,
								triggerHook: .5,
								duration: '200%',
							})
								.setTween(tween2)
								.addTo(controller)
						;
					});
				}

				/* Items */
				/* ----------------------------------------------------- */
				// if ($('.items').length) {
				// 	$('.items').each(function(i, e) {
				// 		var tween = new TimelineMax().add([
				// 			TweenMax.from($(this).find('.item'), 1, {autoAlpha : 0, y: '50px', delay: i}),
				// 		]);

				// 		var tweenParallax = new ScrollMagic.Scene({
				// 				triggerElement: this,
				// 				triggerHook: 1,
				// 				duration: '100%',
				// 			})
				// 			.setTween(tween)
				// 			.addTo(controller)
				// 		;
				// 	});
				// }

				/* Texte large */
				/* ----------------------------------------------------- */
				if ($('.txt-large').length) {
					$('.txt-large').each(function() {
						var tween = new TimelineMax().add([
							TweenMax.from($(this).find('.section_title'), 1, {x: '15%'}),
							TweenMax.from($(this).find('.text'), 1, {x: '25%'}),
						]);

						var TxtLarge = new ScrollMagic.Scene({
								triggerElement: this,
								triggerHook: 1,
								duration: '50%',
							})
								.setTween(tween)
								.addTo(controller)
						;
					})
				}

				/* Branche pages */
				/* ----------------------------------------------------- */
				if ($('.module-branchepage-cartouches').length) {
					$('.module-branchepage-cartouches').each(function() {
						var tween = new TimelineMax().add([
							TweenMax.to($(this).find('#pattern-left-strokes line'), 1, {strokeDashoffset : 0}),
							TweenMax.to($(this).find('#pattern-right-strokes line'), 1, {strokeDashoffset : 0}),
						]);

						var TxtLarge = new ScrollMagic.Scene({
								triggerElement: this,
								triggerHook: 1,
								duration: '300%',
							})
								.setTween(tween)
								.addTo(controller)
						;
					})
				}

				/* Section d'un article */
				/* ----------------------------------------------------- */
				if ($('.post_sections').length) {
					$('.post_sections').each(function() {

						var tween = new TimelineMax().add([
							TweenMax.from($(this).find('.single_title'), 1, {x: '15%'}),
							TweenMax.from($(this).find('.single_paragraphe'), 1, {x: '20%'}),
							TweenMax.from($(this).find('.tomate-oignon'), 1, {y : '50%', x: '-10%'}),
						]);

						var TxtLarge = new ScrollMagic.Scene({
								triggerElement: this,
								triggerHook: 1,
								duration: '50%',
							})
								.setTween(tween)
								.addTo(controller)
						;
					})
				}

			},

			/*
             * Check pour chaque image si son parent est en "adaptative-background". Si c'est le cas, on intègre la bonne url d'image en background-image de ce parent
             */
			adaptativeImages : function(img) {

				var parent = img.parents('.adaptative-background');

				if ( parent.length ) {
					var imgSrc = img[0].currentSrc;

					if (imgSrc != '') {
						parent.css('background-image', 'url(' + imgSrc + ')');
						img.addClass('hidden');
					}
				}

			}

		},

		carte = {

			url_artisan : url_site+'nos-artisans',

			init : function() {
				carte.events();
			},

			events : function () {

				$(document).on('click', '.carte-link', function() {
					let slug = $(this).attr('id');
					location.href = carte.url_artisan + "/" + slug +"#artisans";
				});
			}

		},

		chocolats = {

			init : function() {
				chocolats.events();
			},

			events : function () {
				let i = 1;
				let imgs = $('.site-page-contenu_gallery img');
				if (imgs.length > 0) {
					setInterval(function () {
						imgs.removeClass('light');
						$('img#img' + i).addClass('light');
						if (i === 4) {
							i = 0;
						}
						i++;
					}, 1000);
				}
			}

		},

		/*
         *
         * Elements d'UI
         *
         * Gere les fleches, le scroll, le menu mobile...
         */
		ui = {

			lastScrollTop : 0,

			// A partir de quand on declenche les evenements
			delta : $(window).innerHeight() / 4,

			init : function() {
				$(document)

					/* ----------------------------------------------------- */
					/* MENUS */
					/* ----------------------------------------------------- */

					/* Ouverture */
					/* ----------------------------------------------------- */
					.on('click', '.site-header_menu-btn', function() {
						var btn = $(this);

						if (btn.hasClass('open')) {
							$('body').removeClass('menu-open');
							$('.sub-menu').removeClass('visible');

							setTimeout(function() {
								btn.removeClass('animate');
								btn.removeClass('open');
							}, 100);
						} else {
							btn.addClass('animate');

							setTimeout(function() {
								$('body').addClass('menu-open');
								btn.addClass('open');
							}, 100);
						}

					})

					/* Fermeture */
					/* ----------------------------------------------------- */
					// .on('click', '.menu-close', function(e) {
					// 	var btn = $('.menu-btn');

					// 	$('body').removeClass('menu-open');
					// 	btn.removeClass('open');
					// 	setTimeout(function() {
					// 		btn.removeClass('animate');
					// 		$('.main-nav').toggleClass('open');
					// 	}, 300);
					// })

					/* ----------------------------------------------------- */
					/* SCROLL TO SECTION */
					/* ----------------------------------------------------- */
					.on('click', '.scroll-to-section', function(event) {
						var secondSection = $('section:nth-child(2)');
						if(secondSection.length) {
							if ( $(window).innerWidth() >= 768) {
								$('html,body').animate({
										'scrollTop': secondSection.offset().top },
									500
								);
							}
							else {
								$('html,body').animate({
										'scrollTop': secondSection.offset().top - $('header').innerHeight() },
									500
								);
							}
						}
					})

					/* ----------------------------------------------------- */
					/* SCROLL TO TOP */
					/* ----------------------------------------------------- */
					.on('click', '.scroll-to-top', function(event) {
						$('html,body').animate({
								'scrollTop': 0},
							500
						);
					})

					/* ----------------------------------------------------- */
					/* CLICK SUR LE LOGO DU FOOTER */
					/* ----------------------------------------------------- */
					.on('click', '.footer-logo > img', function(event) {
						$('html,body').animate({
								'scrollTop': 0},
							500
						);
					})


					/* ----------------------------------------------------- */
					/* RECHERCHE */
					/* ----------------------------------------------------- */
					.on('click', '.open-search', function(event) {
						event.preventDefault();
						$(this).toggleClass('active');
						var searchFormContainer = $(this).siblings('.search-form__container');

						ui.searchOpen( searchFormContainer );

					})
					.on('click', '.search-close', function(event) {
						event.preventDefault();

						var searchFormContainer = $(this).siblings('.search-form__container');
						ui.searchClose( searchFormContainer );
					})
					/* (ferme la recherche sur ESC) */
					.on('keyup', function(e) {
						// console.log(e.which);
						if ( e.which == 27 && $('body').hasClass('search-open')) {
							$('body').removeClass('search-open');
							$('.search-form__container').removeClass('open');
						}
					})
				;

				ui.scroll();
			},

			scroll : function () {

				/* HEADER REDUIT */
				/* ------------- */
				// Desactivation en mobile/tablet
				//if ( !device.isMobile() ) {

				// if ( device.isMobile() ) { ui.delta = $('header').height(); }

				// // Header reduit
				var st = $(window).scrollTop();

				// // Si on n'a pas atteint la limite definie
				// if(Math.abs(ui.lastScrollTop - st) <= ui.delta)
				// 	return;

				// if (st > ui.lastScrollTop){
				// 	// downscroll code
				// 	$('.site-header').removeClass('scrolledUp').addClass('scrolledDown');
				// } else {
				// 	if ( st < ui.delta ) {
				// 		$('.site-header').removeClass('scrolledDown scrolledUp');
				// 	}
				// 	else {
				// 		// upscroll code
				// 		$('.site-header').removeClass('scrolledDown').addClass('scrolledUp');
				// 	}
				// }
				// }

				ui.lastScrollTop = st;

				/* SCROLL TO TOP */
				/* ------------- */
				if ($(window).scrollTop() > ($('header').height()) ) {
					$('.scrolltotop').addClass('visible');
					$('.menu-btn').addClass('anchored');
				}
				else {
					$('.scrolltotop').removeClass('visible');
					$('.menu-btn').removeClass('anchored');
				}

				/* NUMBERS ROLL */
				if ( $(".number, .keyfigure__number").length ) {

					if (ui.isInViewport($(".number, .keyfigure__number")) && $(this).prop('Viewed') != 1) {
						$(".number, .keyfigure__number").each(function (i, e) {
							$(this).prop('Counter',0).animate({
								Counter: $(this).data('count')
							}, {
								duration: 2000,
								easing: 'swing',
								step: function (now) {
									if ( now < $(this).data('count') ) {
										$(this).text(Math.ceil(now));
									}
								},
								complete: function() {
									$(this).prop('Viewed', 1);
								}
							});
						});
					}
				}

			},

			isInViewport : function( elem ) {
				var docViewTop = $(window).scrollTop();
				var docViewBottom = docViewTop + $(window).height();

				var elemTop = $(elem).offset().top;
				var elemBottom = elemTop + $(elem).height();

				return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
			},

			searchOpen : function( el ) {
				$('body').toggleClass('search-open');
				// $(this).siblings('.search-form__container').toggleClass('open');
				el.find('.search-field').focus();
			},

			searchClose : function( el ) {
				$('body').removeClass('search-open');
				$('.search-form-container').removeClass('open');
			}

		},

		/*
         *
         * Detection des devices
         *
         */
		device = {

			isMobile : function() {
				return $('html').hasClass('mobile');
			},

			isTablet : function() {
				return $('html').hasClass('tablet');
			},

			isiOS : function() {
				return $('html').hasClass('ios');
			}

		},

		/*
         *
         * Mur social
         *
         */
		socialwall = {

			init : function() {

				$(document)
					.on('click', '.social_wall .tabs .tab', function(e) {
						var plateforme = $(this).data('platform');
						$('.social_wall .tabs .tab').removeClass('active');
						$(this).addClass('active');
						$('.socialwall-list').removeClass('active');
						$('.socialwall-list.'+plateforme).addClass('active');
					})
				;
			}

		},

		/*
         *
         * Lancement du FlatPickr
         *
         */
		flatpickr = {

			init : function() {
				var today = new Date();
				var tomorrow = new Date(today.getFullYear() + '-' + today.getMonth() + '-' + (today.getDate() + 1));

				$('.flatpickr').each(function(i, e) {
					if ( $(e).attr('name').search('end') > 0 ) {
						$(e).flatpickr({
							'dateFormat' : 'd/m/Y',
							'defaultDate' : tomorrow,
							'minDate' : 'today',
							'shorthandCurrentMonth' : true,
							'weekNumbers' : true,
							'locale' : 'fr',
						});
					}
					else {
						$(e).flatpickr({
							'dateFormat' : 'd/m/Y',
							'defaultDate' : today,
							'minDate' : 'today',
							'shorthandCurrentMonth' : true,
							'weekNumbers' : true,
							'locale' : 'fr',
						});
					}
				});

			}

		},

		/*
         *
         * Carte interactive
         *
         */
		map = {

			parameters : null,

			lmap: null,
			container: '',
			maps: [],

			type: '',
			lat: '',
			lng: '',
			height: '',
			zoom: '',
			apiKey: '',
			tiles: '',
			URL: '',

			all: null,
			clusterLayer: null,
			markersJSON: '',
			markers: [],
			lmarker: null,
			activeMarkers: new Array(),
			bounds: new Array(),

			reinit : function() {
				if ( map.maps.length > 0 ) {
					for (var i = 0; i < map.maps.length; i++) {
						map.maps[i].invalidateSize();
					}
				}

				if ( map.lmap !== null ) {
					map.lmap.invalidateSize();
				}
			},

			/* ----------------------------------------------------- */
			/* INITIALISATION DU MODULE */
			/* ----------------------------------------------------- */
			init : function() {
				map.events();

				/* Parametres d'initialisation des maps */
				/* ----------------------------------------------------- */
				if ( $('.items_map_parameters').length > 0 ) {

					$('.items_map_parameters').each(function(i,e) {

						map.parameters = $(e);
						map.container = map.parameters.data('container');
						map.markersJSON = map.parameters.data('markers');
						map.type = map.parameters.data('type');
						map.lat = map.parameters.data('lat');
						map.lng = map.parameters.data('lng');
						map.height = map.parameters.data('height');
						map.zoom = map.parameters.data('zoom');
						map.tiles = map.parameters.data('tiles');
						map.apiKey = map.parameters.data('apikey');
						map.cluster = map.parameters.data('cluster');

						switch ( map.type ) {
							case 'gmap' :
								map.gmap();
								break;

							case 'osm' :
								map.osm();
								break;

							case 'ign' :
								map.ign();
								break;

							case 'mapbox' :
								map.mapbox();
								break;

							default :
								alert( 'Veuillez indiquer un type de carte' );
								break;
						}

					});

				}


				if ( $('#map_parameters').length > 0 ) {

					map.parameters = $('#map_parameters');
					map.container = map.parameters.data('container');
					map.markersJSON = map.parameters.data('markers');
					map.type = map.parameters.data('type');
					map.lat = map.parameters.data('lat');
					map.lng = map.parameters.data('lng');
					map.height = map.parameters.data('height');
					map.zoom = map.parameters.data('zoom');
					map.tiles = map.parameters.data('tiles');
					map.apiKey = map.parameters.data('apikey');
					map.cluster = map.parameters.data('cluster');

					switch ( map.type ) {
						case 'gmap' :
							map.gmap();
							break;

						case 'osm' :
							map.osm();
							break;

						case 'ign' :
							map.ign();
							break;

						case 'mapbox' :
							map.mapbox();
							break;

						default :
							alert( 'Veuillez indiquer un type de carte' );
							break;
					}

				}
			},

			/* ----------------------------------------------------- */
			/* EVENEMENTS */
			/* ----------------------------------------------------- */
			events : function() {
				$(document)

					/* Fermeture de la popup de carte */
					/* ----------------------------------------------------- */
					.on('click', '.map_popup_close', function() {
						map.hidePopup();
					})


					/* Selection d'un point quand on clique sur son item */
					/* ----------------------------------------------------- */
					.on('click', '.object .select-marker', function(e) {
						e.preventDefault();
						$('.objects-list .object').removeClass('active');
						$(this).parents('.object').toggleClass('active');
						map.selectMarker($(this).data('lat'), $(this).data('lng'));
					})

					/* Filtrage de la map en fonction des filtres choisis */
					/* ----------------------------------------------------- */
					.on('click', '.valid_filters', function(event) {
						event.preventDefault();

						var region = $('select[name="filter_regions"]').val(),
							thematic = $('select[name="filter_thematics"]').val(),
							stakeholderTypology = $('select[name="filter_stakeholders"]').val();

						map.filterMarkers(region, thematic, stakeholderTypology);

						$('body').removeClass('map-search-open');

					})

					/* Filtrage par tag */
					/* ----------------------------------------------------- */
					.on('click', '.btn-tag-map', function(e) {
						e.preventDefault();

						$(this).toggleClass('active');

						// map.lmap.closePopup();

						var tags = [];

						$($(this).data('tags-container')).find('.btn-tag-map').each(function(i,e) {

							if ( $(e).hasClass('active') ) {
								tags.push($(e).data('tag-id'));
							}
						});

						map.filterMarkers( tags );

						$('html,body').animate({
								'scrollTop': $('#' + map.container).offset().top },
							500
						);

					})
				;
			},

			/* ----------------------------------------------------- */
			/* GOOGLE MAPS */
			/* ----------------------------------------------------- */
			gmap : function() {
				map.tiles = '';
			},

			/* ----------------------------------------------------- */
			/* OPEN STREET MAP */
			/* ----------------------------------------------------- */
			osm : function() {

				if ( map.tiles == '' ) {
					map.tiles = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
				}

				if ( map.height != '' ) {
					$('#' + map.container).css('height', map.height);
				}

				map.lmap = new L.map(
					map.container,
					{
						attributionControl: false,
						worldCopyJump: true,
						scrollWheelZoom: false,
						maxZoom: 18,
					}
				).setView(
					[
						map.lat,
						map.lng
					],
					map.zoom
				);

				/* Ajout au tableau de maps */
				/* ----------------------------------------------------- */
				map.maps.push(map.lmap);

				/* Creation des POI sur la map */
				/* ----------------------------------------------------- */
				map.createMarkers();

				L.tileLayer(
					map.tiles,
					{
						attribution: 'Contributeurs &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
					}
				).addTo(map.lmap);

				// L.control.layers(null, overlaysMap, {collapsed: false}).addTo(lmap);

				// $('.leaflet-control-layers-list').prepend('<label>Legende</label>');

				// all.addTo(lmap)

			},

			/* ----------------------------------------------------- */
			/* IGN */
			/* ----------------------------------------------------- */
			ign : function() {

				map.URL = "https://wxs.ign.fr/" + map.apiKey + "/geoportail/wmts?" +
					"&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0" +
					"&STYLE=normal" +
					"&TILEMATRIXSET=PM" +
					"&FORMAT=image/jpeg"+
					"&LAYER=ORTHOIMAGERY.ORTHOPHOTOS"+
					"&TILEMATRIX={z}" +
					"&TILEROW={y}" +
					"&TILECOL={x}"
				;


				if ( map.height > 0 ) {
					$('#' + map.container).css('height', map.height);
				}

				var lmap = new L.map(
					map.container
				).setView(
					[
						map.lat,
						map.lng
					],
					map.zoom
				);

				L.tileLayer(
					map.URL,
					{
						minZoom : 0,
						maxZoom : 18,
						attribution : "IGN-F/Geoportail",
						tileSize : 256
					}
				).addTo(map.lmap) ;
			},

			/* ----------------------------------------------------- */
			/* MAPBOX */
			/* ----------------------------------------------------- */
			mapbox : function() {
				map = this;

				mapboxgl.accessToken = map.apiKey;

				map.lmap = new mapboxgl.Map({
					container: map.container,
					style: map.tiles,
					center: [map.lng, map.lat],
					zoom: map.zoom,
					scrollZoom: false,
					renderWorldCopies: false,
				});

				var nav = new mapboxgl.NavigationControl({
					showCompass: true,
					showZoom: true
				});
				map.lmap.addControl(nav, 'top-left');

				// Legende
				// Tableau de categories
				var catsArray = new Array();
				var markers = $.parseJSON( $('#map_markers').val() );

				for (var i = 0; i < markers.length; i++) {
					var marker = markers[i];
					for (var j = 0; j < marker.tags.length; j++) {
						if ( catsArray.indexOf(marker.tags[j]) < 0 ) {
							catsArray.push(marker.tags[j]);
						}
					}
				}

				/* CREATION DES LEGENDES */
				/* ----------------------------------------------------- */
				// if ( catsArray.length > 0 ) {
				// 	var text = "";
				// 	text += "<div class='tags-container'>";

				// 	for (var j = 0; j < catsArray.length; j++) {
				// 		var cat = catsArray[j];
				// 		text += '<a href="" class="btn-tag-map active" data-tags-container=".tags-container" data-tag-id="' + cat.term_id + '" ><img src="../../images/map/marker_' + cat.slug + '.png" /><span>' + cat.name + '</span></a>';
				// 	}

				// 	text += "</div>";
				// 	$('#map_legend').html(text);
				// }


				/* CREATION DES MARKERS */
				/* ----------------------------------------------------- */
				map.createMarkers();
			},

			/* ----------------------------------------------------- */
			/* MARKERS */
			/* ----------------------------------------------------- */
			/*
             * Cree les markers a mettre sur la carte
             *
             * markersArray ( array/null ) : tableau de markers filtres
             * init ( bool ) : initialisation de la map ou filtrage. Si filtrage, on ne recree pas tous les markers
            */
			createMarkers : function( markersArray = null, init = true ) {
				var markers;
				console.log(map.markersJSON);
				if ( markersArray !== null ) {
					markers = markersArray;
				}
				else if ( $('#' + map.markersJSON).length ) {
					markers = $.parseJSON( $('#' + map.markersJSON).val() );
				}
				else if ( $('#map_markers').length ) {
					markers = $.parseJSON( $('#map_markers').val() );
				}

				if ( markers.length > 0 ) {
					switch (map.type) {
						case 'gmap':
							break;

						case 'osm':

							/* Creation d'un cluster de points */
							/* ----------------------------------------------------- */
							if ( map.cluster ) {
								map.clusterLayer = L.markerClusterGroup({
									showCoverageOnHover: true,
									// zoomToBoundsOnClick: true,
									// removeOutsideVisibleBounds: true,
									spiderfyOnMaxZoom: true,
									// singleMarkerMode: true,
								});

								/* Ajout du layer de cluster a la map */
								/* ----------------------------------------------------- */
								map.lmap.addLayer(map.clusterLayer);
								// map.lmap.fitBounds(new L.featureGroup(map.markers).getBounds());
							}

							for (var i = 0; i < markers.length; i++) {
								var marker = markers[i];
								if ( marker.lat !== null && marker.lng !== null ) {
									//console.log(marker);
									map.createMarker(marker, init);
								}
							}


							break;

						case 'ign':
							break;

						case 'mapbox':
							for (var i = 0; i < markers.length; i++) {
								var marker = markers[i];
								map.createMarker(marker, init);
							}
							break;

					}
				}

			},

			/* Creation d'un marker unique */
			/* ----------------------------------------------------- */
			/*
             * createMarker
             * marker ( Marker ) : objet marker
             * init ( Bool ) : verifie si on est a l'initialisation ou non
             */
			createMarker : function( marker, init = true ) {
				switch ( map.type ) {
					case 'gmap':
						break;

					case 'osm':

						var lmarker = L.marker(
							[
								marker['lat'],
								marker['lng']
							]
						);

						if ( map.cluster ) {
							map.clusterLayer.addLayer(lmarker);
						} else {
							lmarker.addTo(map.lmap);
						}

						/* Changement de l'icon de base */
						/* ----------------------------------------------------- */
						var iconUrl = '';

						if ( marker['type'] ) {
							iconUrl = marker['type'] + '.svg';
						} else {
							iconUrl = 'marker.svg';
						}
						// iconUrl: '/nexa/www/content/themes/nexa/assets/images/map/marker_' + iconUrl,
						var icon = L.icon(
							{
								iconUrl: '/content/themes/nexa/assets/images/map/marker_' + iconUrl,
								iconSize: [32, 40],
								iconAnchor: [11, 40],
								popupAnchor: [0, -20],
							}
						);

						if (icon) {
							lmarker.setIcon(icon);
						}

						if ( init ) {
							map.markers.push(lmarker);
						}

						lmarker.id = marker['id'];
						lmarker.class = iconUrl;
						lmarker.title = marker['title'];
						lmarker.content = marker['content'];
						lmarker.image = marker['image'];
						lmarker.link = marker['link'];
						lmarker.type = marker['type'],
							lmarker.region = marker['region'],
							lmarker.typology = marker['typology'],
							lmarker.thematic = marker['thematic'];

						// lmarker.on('click', function(e) {
						// 	$('.objects-list').remove();
						// });

						var popup = map.createPopup(lmarker);
						lmarker.bindPopup(popup);
						break;

					case 'ign':
						break;

					case 'mapbox':

						var el = document.createElement('div');
						el.className = 'marker' + ' marker_' + marker.tags[0].slug;

						var lmarker = new mapboxgl.Marker({
							'element' : el,
							'anchor' : 'bottom',
						})
							.setLngLat([marker.lng, marker.lat])
							.addTo(map.lmap);

						lmarker.id = marker.id;
						lmarker.title = marker.title;
						lmarker.content = marker.content;
						lmarker.image = marker.image;
						lmarker.link = marker.link;
						lmarker.type = marker.type,
							lmarker.region = marker.region,
							lmarker.typology = marker.typology,
							lmarker.thematic = marker.thematic;

						var popup = map.createPopup(lmarker);

						lmarker.setPopup(popup);

						if ( init ) {
							map.markers.push(lmarker);
						}

						break;

				}

			},

			/* Cree une liste d'objets cliquables */
			/* ----------------------------------------------------- */
			createMarkersList : function( markers ) {

				// On recree la popup
				var popup = '<div class="objects-list">';

				for (var i = 0; i < markers.length; i++) {
					var mrkr = markers[i];
					popup += '<div class="object">';

					popup += '<a href="" class="select-marker" data-lat="' + mrkr.lat + '" data-lng="' + mrkr.lng + '">' + mrkr.title + '</a>';
					popup += '<div class="object-actions">';
					//popup += '<div class="object-position"><a href="" class="select-marker" data-lat="' + mrkr.lat + '" data-lng="' + mrkr.lng + '"><i class="fas fa-map-marker-alt"></i></a></div>';
					popup += '<div class="object-link"><a href="' + mrkr.link + '"><i class="fas fa-angle-right"></i></a></div>';
					popup += '</div>';
					popup += '</a>';

					popup += '</div>';
				}
				popup += '</div>';

				return popup;

			},

			/* Filtre les markers en fonction des tags */
			/* ----------------------------------------------------- */
			filterMarkers : function( region, thematic, typology ) {

				map.activeMarkers = new Array();

				for ( var i = 0; i < map.markers.length; i++ ) {
					var marker = map.markers[i];
					var active = false;

					// Si region, thematic et typology sont vides, on affiche tout
					if ( region == '' && thematic == '' && typology == '' ) {
						active = true;
					}
					else {
						// Si region ok
						if ( region && marker.region ) {
							for (var j = 0; j < marker.region.length; j++) {
								if ( region.indexOf(marker.region[j]) >= 0 ) {
									active = true;
								}
							}
						}

						// Si thematic ok
						if ( thematic && marker.thematic ) {
							for (var j = 0; j < marker.thematic.length; j++) {
								if ( thematic.indexOf(marker.thematic[j]) >= 0 ) {
									active = true;
								}
							}
						}

						// Si typology ok
						if ( typology && marker.typology ) {
							for (var j = 0; j < marker.typology.length; j++) {
								if ( typology.indexOf(marker.typology[j]) >= 0 ) {
									active = true;
								}
							}
						}

					}

					if ( active ) {
						// console.log(marker);
						map.activeMarkers.push(marker);
					}
					else {
						$('.mapboxgl-marker').remove();
						marker.remove();
					}

				}

				map.refreshMarkers();
			},

			/* Rafraichit l'affichage des clusters */
			/* ----------------------------------------------------- */
			refreshMarkers : function() {

				switch ( map.type ) {
					case 'gmap':
						break;

					case 'osm':
					case 'ign':

						if ( map.cluster ) {
							map.clusterLayer.clearLayers();
						}

						if ( map.activeMarkers ) {
							var activeMarkersArray = new Array();

							for ( var j = 0; j < map.activeMarkers.length; j++ ) {

								var marker = map.activeMarkers[j], lmarker = new Array();

								lmarker['id'] = marker.id;
								lmarker['title'] = marker.title;
								lmarker['content'] = marker.content;
								lmarker['image'] = marker.image;
								lmarker['link'] = marker.link;
								lmarker['tags'] = marker.tags;
								lmarker['lat'] = marker.getLatLng().lat;
								lmarker['lng'] = marker.getLatLng().lng;
								lmarker['type'] = marker.type,
									lmarker['region'] = marker.region,
									lmarker['typology'] = marker.typology,
									lmarker['thematic'] = marker.thematic;

								activeMarkersArray.push(lmarker);
							}
						}

						break;

					case 'mapbox':
						if ( map.activeMarkers ) {
							var activeMarkersArray = new Array();

							for ( var j = 0; j < map.activeMarkers.length; j++ ) {

								var marker = map.activeMarkers[j], lmarker = new Array();

								lmarker['id'] = marker.id;
								lmarker['title'] = marker.title;
								lmarker['content'] = marker.content;
								lmarker['image'] = marker.image;
								lmarker['link'] = marker.link;
								lmarker['tags'] = marker.tags;
								lmarker['lat'] = marker.getLngLat()['lat'];
								lmarker['lng'] = marker.getLngLat()['lng'];

								activeMarkersArray.push(lmarker);
							}

						}
						break;

				}

				map.createMarkers(activeMarkersArray, false);

			},

			/* Selectionne un marker en fonction du click sur un item */
			/* ------------------------------------------------------ */
			selectMarker : function( lat, lng ) {

				for (var i = map.markers.length - 1; i >= 0; i--) {
					marker = map.markers[i];

					if ( marker._latlng['lat'] == lat && marker._latlng['lng'] == lng ) {
						map.cluster.zoomToShowLayer(marker);
						marker.openPopup();
					}
				}

			},

			/* ----------------------------------------------------- */
			/* INFOWINDOW */
			/* ----------------------------------------------------- */
			createPopup : function(marker) {

				// On recree la popup
				var popup, p = '';

				if ( marker.title != '' ) {
					popup = '';
					popup += '<div class="map_popup">';
					popup += '<div class="map_popup_content">';
					if ( marker.link != '' ) { popup += '<a href="' + marker.link + '">'; }

					if ( marker.image != '' ) {
						popup += '<div class="map_popup_content_image">';
						popup += '<img src="' + marker.image + '" />';
						popup += '</div>';
					}

					if ( marker.type != '' ) {
						popup += '<div class="map_popup_content_type">';
						popup += '<a href="" class="btn btn-'+marker.type+'">' + marker.type + '</a>';
						popup += '</div>';
					}

					popup += '<div class="map_popup_content_container">';
					if ( marker.title != '' ) { popup += '<p class="map_popup_content_titre">' + marker.title + '</p>'; }
					if ( marker.content != '' ) { popup += '<div class="map_popup_content_texte">' + marker.content + '</div>'; }
					popup += '</div>';

					if ( marker.link != '' ) { popup += '</a>'; }
					popup += '</div>';
					popup += '</div>';

					if ( popup != '' ) {

						switch ( map.type ) {
							case 'gmap':
								break;

							case 'osm':
							case 'ign':
								p = L.popup({
									minWidth: 250,
									maxWidth: 350,
								}).setContent(popup);
								break;

							case 'mapbox':
								var coords = marker.getLngLat();
								var p = new mapboxgl.Popup({
									closeButton: false,
								})
									.setMaxWidth("240px")
									.setLngLat([coords.lng, coords.lat])
									.setHTML(popup);
								break;

						}
					}
				}

				return p;

			},

			/* ----------------------------------------------------- */
			/* POPUPWINDOW */
			/* ----------------------------------------------------- */
			popupWindow : function(item) {

				// On vide la popup si elle etait visible
				$('#map_popup_container').empty();

				var titre, texte, image, lien, popup;

				titre = item.titre;
				texte = item.texte;
				image = item.image;
				lien = item.lien;

				// On recree la popup
				popup = '<div class="map_popup">';
				popup += '<div class="map_popup_content">';
				popup += '<div class="map_popup_close"><i class="icn icn-cross"></i></div>';
				if ( lien != '' ) { popup += '<a href="' + lien + '">'; }
				if ( image != '' ) { popup += '<div class="map_popup_content_image"><img src="' + image.sizes.medium + '" /></div>'; }
				if ( titre != '' ) { popup += '<p class="map_popup_content_titre">' + titre + '</p>'; }
				if ( texte != '' ) { popup += '<p class="map_popup_content_texte">' + texte + '</p>'; }
				if ( lien != '' ) { popup += '</a>'; }
				popup += '</div>';
				popup += '</div>';

				// Position du point
				var top = $('g#town-' + item.ville.value).position().top;
				var left = $('g#town-' + item.ville.value).position().left;

				// Position du parent
				var ptop = $('#carteinteractive').position().top;
				var pleft = $('#carteinteractive').position().left;

				$(popup).appendTo('#map_popup_container');
				map.showPopup((top - ptop), (left - pleft));

			},

			showPopup : function(top, left) {
				$('#map_popup_container').css({'top': top, 'left': left});
				$('#map_popup_container').addClass('open');
			},

			hidePopup : function() {
				$('#map_popup_container').removeClass('open');
			}

		},


		/*
         *
         * Popups
         *
         */
		popup = {

			container : '',

			/* ----------------------------------------------------- */
			/* Initialisation */
			/* ----------------------------------------------------- */
			init : function() {

				popup.eventListeners();

			},

			/* ----------------------------------------------------- */
			/* Events */
			/* ----------------------------------------------------- */
			eventListeners : function() {
				$(document)

					/* Ouverture */
					/* ----------------------------------------------------- */
					.on('click', '.btn-open-popup', function(e) {

						e.preventDefault();

						var popup_container = $(this).data('popup');

						/* Ouverture */
						/* ----------------------------------------------------- */
						popup.open(popup_container);

					})

					/* Fermeture */
					/* ----------------------------------------------------- */
					.on('click', '.btn-close-popup', function(e) {
						var popup_container = $(this).data('popup');
						popup.close(popup_container);
					})

					/* Fermeture sur ESC */
					/* ----------------------------------------------------- */
					.on('keyup', function(e) {
						if ( e.which == 27 && $('body').hasClass('popup-open')) {
							popup.close('.popup-sit');
						}
					})

				;
			},

			/* ----------------------------------------------------- */
			/* Ouverture */
			/* ----------------------------------------------------- */
			open : function(container) {
				$('body').addClass('popup-open');
				$(container).addClass('open');
			},

			/* ----------------------------------------------------- */
			/* Fermeture */
			/* ----------------------------------------------------- */
			close : function(container) {
				$('body').removeClass('popup-open');
				$(container).removeClass('open');
			},

			/* ----------------------------------------------------- */
			/* Remplacement du contenu */
			/* ----------------------------------------------------- */
			replaceContent : function(container, content) {
				$(container + "__content").empty();
				$(container + "__content").html(content);
			}

		},

		/*
         *
         * Popup Profile
         *
         */
		profile = {

			currentStep : 1,
			container : '.popup__profile',

			init : function() {

				if ( !profile.checkCookie(profile.container)) {
					profile.open();
					profile.addCookie(profile.container, "1", 365);
				}

				profile.currentstep = $(profile.container + ' .step-counter').val();

				$(document)
					.on('click', profile.container + '_close', function(e) {
						profile.close();
					})
					.on('click', '.prev-step', function(e) {
						e.preventDefault();
						profile.prev(e);
					})
					.on('click', '.next-step', function(e) {
						e.preventDefault();
						profile.next(e);
					})
					.on('click', '.popup__profile_disagree', function(e) {
						e.preventDefault();
						profile.close();
					})
				;

				profile.update(1);
			},

			/*
            * Ouverture de la pop
            */
			open : function() {
				$(profile.container).css('display', 'block');

				setTimeout(function() {
					$(profile.container).removeClass('close');
				}, 100);
			},

			/*
            * Fermeture de la pop
            */
			close : function() {
				$(profile.container).addClass('close');

				setTimeout(function() {
					$(profile.container).css('display', 'none');
				}, 1000);
			},

			/*
            * Etape precedente
            */
			prev : function(e) {
				var nb;

				$(profile.container + ' .step').each(function(i,el) {
					var step = $(el).data('step');

					if ( step == profile.currentStep ) {
						profile.hide($(el));
					} else if ( step == profile.currentStep - 1 ) {
						profile.show($(el));
						nb = step;
					}
				});

				profile.update(nb);

			},

			/*
            * Etape suivante
            */
			next : function(e) {
				var nb;

				$(profile.container + ' .step').each(function(i,el) {
					var step = $(el).data('step');

					if ( step == profile.currentStep ) {
						profile.hide($(el));
					} else if ( step == profile.currentStep + 1 ) {
						profile.show($(el));
						nb = step;
					}
				});

				profile.update(nb);
			},

			/*
            * Cache la section
            */
			hide : function( obj ) {
				obj.removeClass('revealed').addClass('hidden');
				setTimeout(function() { obj.css('display', 'none'), 800 });
			},

			/*
            * Montre la section
            */
			show : function( obj ) {
				obj.css('display', 'block');
				setTimeout(function() { obj.removeClass('hidden').addClass('revealed') , 100 });
			},

			/*
            * Met a jour les steps
            */
			update : function( nb ) {

				profile.currentStep = nb;
				$(profile.container + ' .step-counter').val(nb);
				if ( nb == 1 ) { $('.prev-step').addClass('hidden'); } else { $('.prev-step').removeClass('hidden'); }
				if ( nb == $('.step').length ) { $('.next-step').addClass('hidden'); } else { $('.next-step').removeClass('hidden'); }

			},

			/*
            *
            */
			addCookie : function(cname, cvalue, cexpires) {

				var d = new Date();
				d.setTime(d.getTime() + (cexpires*24*60*60*1000));
				var expires = "expires="+ d.toUTCString();

				document.cookie = cname + "=" + cvalue + "; expires=" + cexpires;

			},

			/*
            *
            */
			checkCookie : function(cname) {
				var name = cname + "=";
				var decodedCookie = decodeURIComponent(document.cookie);
				var ca = decodedCookie.split(';');
				for(var i = 0; i <ca.length; i++) {
					var c = ca[i];
					while (c.charAt(0) == ' ') {
						c = c.substring(1);
					}
					if (c.indexOf(name) == 0) {
						return c.substring(name.length, c.length);
					}
				}
				return "";
			},

		},

		ajax = {

			init : function() {
				this.filterEvents();
				// this.filterPosts();
				// this.filterItems();
				// this.loadMore();
				this.suggest();
				this.tabs();
				this.events();
			},

			events : function () {

				$(document)

					// Chargemenr des indicateurs d'un type
					.on('click', '.btn-get-indicators', function(event) {
						event.preventDefault();

						var
							item = $(this).data('item'),
							itemID = $(this).data('item-id'),
							type = $(this).data('type'),
							tpl = $(this).data('tpl'),
							refresh = $(this).parents('section').find('.ajax-items-refresh')
						;

						$('.loader').addClass('display');

						$.ajax({

							type: "post",
							url: ajax_var.url,
							data: "action=get_indicators&nonce="+ajax_var.nonce+"&type="+type+"&item="+item+"&itemid="+itemID+"&tpl="+tpl,

							success: function(msg){

								// On vide le slider et on le deconstruit
								refresh.empty();

								// On le re-remplit
								refresh.html(msg);

								$('.loader').removeClass('display');

							}

						});

					})

					// Filtres de recherche d'items
					.on('change', '.items-search select', function(event) {
						ajax.filterItems($(this));
					})

					// Filtre de recherche par tag
					.on('click', '.btn-tag-filter', function(e) {
						$(this).toggleClass('active');
						ajax.filterItems($(this));
					})

					// Remplacement du chargement classique par de l'ajax
					.on('click', '.btn-ajax', function(event) {
						event.preventDefault();

						var template = $(this).data('ajax-tpl');
						var refresh = $('.tab-contents');

						$('.loader').addClass('display');

						$.ajax({

							type: "post",
							url: ajax_var.url,
							data: "action=gtp&nonce="+ajax_var.nonce+"&template="+template,

							success: function(msg){

								// On vide le slider et on le deconstruit
								refresh.empty();

								// On le re-remplit
								refresh.html(msg);

								$('.loader').removeClass('display');

							}

						});

					})

					.on('click', '.page-tabs .tab-button', function(e) {
						e.preventDefault();
						ajax.tabs($(this), $(this).attr('href'));
					})

					.on('click', '.items__all .tab-button', function(e) {
						e.preventDefault();

						var btn = $('.page-tabs .tab-button[href="' + $(this).attr('href') + '"');

						ajax.tabs(btn, btn.attr('href'));

						$(window).scrollTop(0);
					})

				;

				window.onpopstate = function (e) {
					ajax.history(e);
				}

			},

			// Filtrage des items
			filterItems : function( el ) {

				var
					form,
					item,
					itemid,
					types,
					thematic,
					region,
					template,
					refresh,
					data,
					tags = [],
					btns
				;

				form = $(el).parents('form');

				// On parcourt les enfants du formulaire pour recuperer les valeurs
				for (var i = 0; i < form.children().length; i++) {
					var el = form.children()[i];

					switch ( el.name ) {

						case 'stakeholder_typology':
							types = $(el).val();
							break;
						case 'thematic':
							thematic = $(el).val();
							break;
						case 'region':
							region = $(el).val();
							break;

					}
				}

				// s'il y a des tags
				if ( form.find('.btn-tag-filter').length > 0 ) {
					btns = form.find('.btn-tag-filter');

					$(btns).each(function(i, e) {
						if ( $(e).hasClass('active') ) {
							tags.push($(e).data('tag-id'));
						}
					});
				}

				item = form.find('input[name="item"]').val();
				itemid = form.find('input[name="itemid"]').val();
				template = form.find('input[name="template"]').val();
				refresh = form.parents('section').find('.ajax-items-refresh');

				data = "action=filter_items";
				data += "&nonce="+ajax_var.nonce;
				data += "&item="+item;
				data += "&itemid="+itemid;
				data += "&types="+types;
				data += "&thematic="+thematic;
				data += "&region="+region;
				data += "&tags="+tags;
				data += "&template="+template;

				$('.loader').addClass('display');

				$.ajax({

					type: "post",
					url: ajax_var.url,
					data: data,

					success: function(msg){

						// On vide le slider et on le deconstruit
						refresh.empty();

						// On le re-remplit
						refresh.html(msg);

						$('.loader').removeClass('display');

						// Mise a jour de la carte
						if ( $('.items-map').length ) {

							map.filterMarkers(region, thematic, types);

						}

					}

				});



			},

			// Filtrage des evenements
			filterEvents : function() {

				$(document).on('click', '.events-filter', function(e) {
					e.preventDefault();

					var type, start, end, params, ordre, tri, perpage;

					$('.events-filter').not($(this)).removeClass('active');
					$(this).toggleClass('active');

					/* Si le bouton est actif on filtre, */
					/* ----------------------------------------------------- */
					if ( $(this).hasClass('active')) {

						type = $(this).data('type');
						start = $(this).data('start');
						end = $(this).data('end');

					}
						/* Sinon on affiche les resultats de base */
					/* ----------------------------------------------------- */
					else {

						type = '';
						start = $(this).data('start');
						end = $(this).data('end');

					}

					params = $('#events-params');
					ordre = params.data('ordre');
					perpage = params.data('perpage');
					tri = params.data('tri');

					if ( start && end ) {

						$('.events-loader').addClass('display');

						$.ajax({

							type: "post",
							url: ajax_var.url,
							data: "action=refresh_events&nonce="+ajax_var.nonce+"&type="+type+"&start="+start+"&end="+end+"&ordre="+ordre+"&tri="+tri+"&perpage="+perpage,

							success: function(msg){

								// On vide le slider et on le deconstruit
								$('.events-items-slider').empty();
								// var slider = $('.events-items-slider').getSlick();
								$('.events-items-slider').slick('unslick');
								// slider.unslick();

								// On le re-remplit
								$('.events-items-slider').html(msg);

								// on relance l'init du slider
								appChild.sliders();

								$('.events-loader').removeClass('display');

							}

						});

					}

				})

			},

			// Filtrage des news
			filterPosts : function() {

				$(document).on('click', '.btn-tag-filter', function(e) {
					e.preventDefault();

					var container, tags, params, ordre, tri, perpage, taxonomy, data;

					$(this).toggleClass('active');

					container = $(this).data('tags-container');
					taxonomy =  $(this).data('tax');

					// Datas
					post_type = $(container).data('post_type');
					order = $(container).data('order');
					orderby = $(container).data('orderby');
					posts_per_page = $(container).data('posts_per_page');
					library = $(container).data('library');

					refresh = $(container).data('refresh');
					tpl = $(refresh).data('tpl');

					// On recupere les tags actifs
					tags = [];

					if ( $(this).data('tag-id') != '0' ) {

						$('.btn-tag-filter.all-filter').removeClass('active');

						$(container).find('.btn-tag-filter.active').each(function(i,e) {
							tags.push( $(e).data('tag-id') );
						});

					} else {

						$('.btn-tag-filter').not($(this)).removeClass('active');

					}

					// Parametres de la recherche
					data = "action=refresh_posts"
						+ "&nonce=" + ajax_var.nonce
						+ "&post_type=" + post_type
						+ "&posts_per_page=" + posts_per_page
						+ "&orderby=" + orderby
						+ "&order=" + order
						+ "&taxonomy=" + taxonomy
						+ "&tags=" + tags
						+ "&tpl=" + tpl
					;

					$('.loader').addClass('display');

					$.ajax({

						type: "post",
						url: ajax_var.url,
						data: data,

						success: function(msg){
							$(refresh).find('.row').empty();
							$(refresh).find('.row').html(msg);
							$('.loader').removeClass('display');
						}

					});
				});

			},


			// Bouton Charger +
			loadMore : function() {

				$(document).on('click', '.btn-load-more', function(e) {
					e.preventDefault();

					var btn, tpl, post_type, posts_per_page, paged, orderby, order, refresh, tax, tags;

					btn = $(this);
					post_type = $(this).data('post_type');
					posts_per_page = $(this).data('posts_per_page');
					paged = $(this).data('paged');
					orderby = $(this).data('orderby');
					order = $(this).data('order');
					tpl = $(this).data('tpl');

					container = $(this).data('tags-container');
					refresh = $(container).data('refresh');
					tpl = $(refresh).data('tpl');
					tax = $(container).data('tax');


					// On recupere les tags actifs
					tags = [];

					$(container).find('.btn-tag-filter.active').each(function(i,e) {
						tags.push( $(e).data('tag-id') );
					});


					// Parametres de la recherche
					data = "action=load_more"
						+ "&nonce=" + ajax_var.nonce

						+ "&post_type=" + post_type
						+ "&posts_per_page=" + posts_per_page
						+ "&paged=" + (parseInt(paged) + 1)
						+ "&orderby=" + orderby
						+ "&order=" + order
						+ "&tpl=" + tpl
						+ "&tax=" + tax
						+ "&tags=" + tags
					;

					$('.news-loader').addClass('display');

					$.ajax({

						type: "post",
						url: ajax_var.url,
						data: data,

						success: function(msg){
							var response = JSON.parse(msg);
							$(response.tpl).appendTo($(refresh).find('.row'));

							if ( response.max_num_pages > response.page ) {
								btn.css('display', 'inline-block');
							} else {
								btn.css('display', 'none');
							}

							$('.news-loader').removeClass('display');
						}

					});

				});
			},

			// SUGGESTION DE RECHERCHE
			suggest : function() {
				$('.search-form__field').keyup(function(e) {

					val = $(this).val();

					// On ne déclenche que s'il y a 3 caracteres tapes
					if ( val.length > 2 ) {

						// Parametres de la recherche
						data = "action=suggest"
							+ "&nonce=" + ajax_var.nonce
							+ "&search=" + val
							+ "&perpage=3"
						;

						$.ajax({

							type: "post",
							url: ajax_var.url,
							data: data,

							success: function(msg){
								if ( msg != '' ) {
									$('.search-form__suggestions').html(msg).addClass('visible');
								} else {
									$('.search-form__suggestions').empty().removeClass('visible');
								}
							}

						});

					}

				});

				$('.search-form__field').blur(function(e) {
					$('.search-form__suggestions').empty().removeClass('visible');
				});
			},

			// Onglets des pages
			tabs : function(btn = null, tab = '', history = false) {

				// Si on n'a pas de bouton ni tab, on checke l'url
				if ( btn == null ) {
					if ( tab == '' ) {
						tab = ajax.getUrlParameter('tab');
					}

					btn = $('.tab-button[data-objects="' + tab + '"]');
				}
				else {
					tab = btn.attr('href');
				}

				/* Variables */
				var
					objects = btn.data('objects'),
					item = btn.data('item'),
					itemid = btn.data('itemid'),
					data,
					title,
					url = window.location.href,
					urlArray = url.split('/'),
					currentTab = urlArray[urlArray.length - 1],
					slug = urlArray.pop()
				;


				// Toggle du bouton
				btn.parents('.tabs').find('.tab').removeClass('tab-active');
				btn.parents('.tab').addClass('tab-active');

				// HISTORY & BREADCRUMB
				if ( tab !== 'undefined' && tab !== '' ) {

					$('.loader').addClass('display');

					if ( !history ) {
						window.history.pushState({id: tab}, tab, '?tab=' + tab);
					}

					data  = "action=gtp";
					data += "&nonce="+ajax_var.nonce;
					data += "&objects="+objects;
					data += "&item="+item;
					data += "&itemid="+itemid;

					if ( objects == 'profile' ) {
						data += "&template=template-parts/" + item + "s/" + objects;
					}
					else {
						data += "&template=template-parts/" + item + "s/" + objects;
					}

					$.ajax({

						type: "post",
						url: ajax_var.url,
						data: data,

						success: function(msg){

							// On vide le conteneur
							$('.tab-contents').empty();

							// On le re-remplit
							$('.tab-contents').html(msg);

							// Fil d'ariane
							ajax.breadcrumbRefresh(tab);

							// On vide le slider et on le deconstruit
							if ( $('.thematics__slider').length ) {
								// on relance l'init du slider
								appChild.sliders();
							}


							// On initialise la map
							map.init();

							$('.loader').removeClass('display');

						}

					});

					map.reinit();

				}

			},

			singleAjax : function(btn = null) {

				if ( btn !== null ) {

					var
						tpl = btn.data('tpl'),
						item = btn.data('item'),
						id = btn.data('item-id'),
						slug = btn.data('slug'),
						title = btn.data('title'),
						refresh = btn.parents('.ajax-items-refresh')
					;

					data  = "action=get_single";
					data += "&nonce="+ajax_var.nonce;
					data += "&tpl="+tpl;
					data += "&id="+id;

					$('.loader').addClass('display');

					// HISTORY
					window.history.pushState({id: item+id}, null, '?' + item + '=' + slug + '&id=' + id);

					$.ajax({

						type: "post",
						url: ajax_var.url,
						data: data,

						success: function(msg){

							refresh.empty();

							// On le re-remplit
							refresh.html(msg);

							$('.loader').removeClass('display');

						}

					});

				}

			},

			/* Gestion de l'historique */
			/* ----------------------------------------------------- */
			history : function(e) {
				var tab;
				if (e.state.id != '' )
					tab = e.state.id;
				else {
					tab = profile;
				}

				ajax.tabs(null, tab, true);
			},

			/* MaJ du breadcrumb */
			/* ----------------------------------------------------- */
			breadcrumbRefresh : function(item) {

				var spans = $('.breadcrumbs span');

				// on fait le tour des span du fil d'ariane
				spans.each(function(i, e) {

					// Pour le dernier,
					if ( i == (spans.length - 1) ) {

						// on verifie s'il a été généré par bvt ou non
						if ( $(e).hasClass('post')) {
							// à ce moment là on ajoute notre span
							$('.breadcrumbs').append('&nbsp;&rarr;&nbsp;<span class="custom">' + item.charAt(0).toUpperCase() + item.slice(1) + '</span>');
						}
						else {
							$(e).addClass('custom').text(item.charAt(0).toUpperCase() + item.slice(1));
						}
					}
				});

			},

			/* Helper */
			/* ----------------------------------------------------- */
			getUrlParameter : function(name) {
				name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
				var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
				var results = regex.exec(window.location.search);

				return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
			}

		},


		gallery = {

			init : function() {

				$(document).on('click', '.btn-tag-filter-hide', function() {

					$('.btn-tag-filter-hide').not($(this)).removeClass('active');
					$(this).toggleClass('active');

					gallery.filterTags($(this));

				})

				// gallery.refreshLibrary( 'isotope', '#gallery', '.gallery-item');

			},

			filterTags : function(btn) {

				var
					filterList = btn.data('tags-container'),
					tagId = btn.data('tag-id'),

					library = $(filterList).data('library'),
					objects = $(filterList).data('objects'),
					refresh = $(filterList).data('refresh'),

					tags,
					tagsData
				;


				$(objects).each(function(i, e ) {

					tagsData = $(e).data('tags');

					if ( tagsData.length > 0 ) {

						tags = JSON.parse(tagsData);

						if ( tagId !== '' ) {

							if ( tags == tagId ) {
								gallery.displayItem($(e));
							}
							else {
								gallery.hideItem($(e));
							}

						} else {
							gallery.displayItem($(e));
						}

					}
					else {
						gallery.hideItem($(e));
					}

				})

				if ( library ) {
					gallery.refreshLibrary(library, $(refresh), objects);
				}

			},

			displayItem : function( obj ) {
				obj.addClass('display');
			},

			hideItem : function( obj ) {
				obj.removeClass('display');

			},

			refreshLibrary : function ( library, container, item ) {

				if ( typeof container == "string" ) {
					container = $(container);
				}

				// switch ( library ) {
				// 	case 'isotope':
				// 		container.isotope({
				// 			percentPosition: true,
				// 			horizontalOrder: true,
				// 			itemSelector: item,
				// 			masonry: {
				// 				columnWidth: '.gallery-sizer'
				// 			},
				// 			filter: '.display',
				// 			stagger: 30
				// 		})
				// 	break;
				// }

			}

		}

	;

	/* ----------------------------------------------------- */
	/* ON READY */
	/* ----------------------------------------------------- */
	$(document).ready(function() {

		/* Initialisation du composant principal */
		appChild.init();
		//appChild.intro();

		/* Si Hors Mobile, on declenche le parallax */
		// if (!device.isMobile() || $(window).innerWidth() > 768 ) {
		// 	$('html').addClass('parallax');
		// 	appChild.parallax();
		// 	$('body').addClass('menu-visible');
		// }

		/* Initialisation des composants */
		carte.init();
		chocolats.init();
		ui.init();
		ajax.init();
		socialwall.init();
		flatpickr.init();
		map.init();
		profile.init();
		popup.init();
		gallery.init();
		gallery.filterTags($('.btn-tag-filter-hide').first());

		/* On trigger un resize pour les images adaptatives */
		$(window).trigger('resize');

	});

	/* ----------------------------------------------------- */
	/* AU SCROLL */
	/* ----------------------------------------------------- */
	$(window).scroll(function() {
		ui.scroll();
	});

	/* ----------------------------------------------------- */
	/* AU REDIMENSIONNEMENT */
	/* ----------------------------------------------------- */
	$(window).resize(function() {
		$('.adaptative-background > img').each(function(i,e) {
			appChild.adaptativeImages($(e));
		})
	});

})(jQuery)
